<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x pt-10">
      <div class="cell small-12 text-center">
        <h1>Checkout</h1>
      </div>
    </div>
    <div class="bordered pb-4">
      <div class="grid-x grid-padding-x pt-2">
        <div class="cell small-12 medium-6">
          <h6>Invoice Address</h6>
        </div>
      </div>
      <form @submit.prevent="goToPayment">
        <div class="grid-x grid-padding-x">
          <div class="cell small-6 medium-3">
            <input
              v-model="invoiceAddress.firstName"
              required
              type="text"
              placeholder="First Name">
          </div>
          <div class="cell small-6 medium-3">
            <input
              v-model="invoiceAddress.lastName"
              required
              type="text"
              placeholder="Last Name">
          </div>
          <div class="cell small-12 medium-6">
            <input
              v-model="invoiceAddress.email"
              required
              type="email"
              placeholder="Email">
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-6">
            <input
              v-model="invoiceAddress.contactNumber"
              required
              type="text"
              placeholder="Contact number">
          </div>
          <div class="cell small-12 medium-6">
            <input
              v-model="invoiceAddress.lineOne"
              type="text"
              placeholder="Address line one">
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-6">
              <input
                v-model="invoiceAddress.lineTwo"
                type="text"
                placeholder="Address line two">
          </div>
          <div class="cell small-12 medium-6">
              <input
                v-model="invoiceAddress.town"
                type="text"
                placeholder="Town">
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-6">
            <input
              v-model="invoiceAddress.postCode"
              type="text"
              placeholder="Postcode">
          </div>
          <div class="cell small-12 medium-6">
            <textarea
              v-if="deliverToInvoiceAddress === true"
              v-model="deliveryAddress.notes"
              placeholder="Any notes for the delivery driver">
            </textarea>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-6 text-right">
            <label>
              <input
                v-model="deliverToInvoiceAddress"
                type="checkbox"
                checked>
                Deliver to invoice address
            </label>
          </div>
        </div>
        <div v-if="deliverToInvoiceAddress === false">
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-6">
              <h6>Delivery address</h6>
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-6">
              <input
                v-model="deliveryAddress.lineOne"
                type="text"
                placeholder="Address line one">
            </div>
            <div class="cell small-12 medium-6">
              <input
                v-model="deliveryAddress.lineTwo"
                type="text"
                placeholder="Address line two">
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-6">
              <input
                v-model="deliveryAddress.town"
                type="text"
                placeholder="Town">
            </div>
            <div class="cell small-12 medium-6">
              <input
                v-model="deliveryAddress.postCode"
                type="text"
                placeholder="Postcode">
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-6">
              <textarea
                v-model="deliveryAddress.notes"
                placeholder="Any notes for the delivery driver">
              </textarea>
            </div>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-8 medium-4 medium-offset-4 small-offset-2">
            <button
              class="button rounded-tall expanded">Next</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'Checkout',
  data() {
    return {
      totals: {},
      invoiceAddress: {},
      deliveryAddress: {},
      deliverToInvoiceAddress: true,
    };
  },
  methods: {
    goToPayment() {
      const postData = {};
      postData.basketId = this.$store.state.basketId;
      postData.invoiceAddress = this.invoiceAddress;
      postData.deliveryAddress = this.deliveryAddress;
      if (this.$store.state.customer.id) {
        postData.customerId = this.$store.state.customer.id;
      } else {
        postData.customerId = 0;
      }
      axios.post('/customers/create.json', postData)
        .then((response) => {
          const customerData = response.data.customer;
          const orderData = response.data.order;
          this.$store.commit('customer', { customerData });
          this.$store.commit('order', { orderData });
          this.$router.push('/payment');
        });
    },
    getBasket() {
      axios.get(`/baskets/get/${this.$store.state.basketId}.json`)
        .then((response) => {
          this.totals = response.data.totals;
        });
    },
  },
  mounted() {
    this.getBasket();
  },
};
</script>
